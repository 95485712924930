<template>
  <div class="mainArea">
    <div class="contentCenter">
      <h1><u>KATHARINA KEMMERLING</u></h1>
      <h1><u>JÜRGEN BAUMANN</u></h1><br>
      <h1>ZWIESTÜCK</h1>
      <h2>Sculpture, wall relief, drawing</h2>
      <br>
      <h4>Opening: Thu. 06.03.25, 18:00</h4>
      <h2>Artist talk and closing: 22.03.25, 14:00</h2>
      <br>
      <h2>07.03.25 - 22.03.25</h2>
      <h2>Visits by appointment: mail@fomoartspace.ch</h2>
      <br>
      <img src="../assets/img/upcoming_14.jpg" alt="" class="contentImg">
      <br>
      <h2>At night, imperceptibly, the boundaries between spaces and memories blur. A flickering light slips through the door, casting a dark shadow into the long corridor.</h2>
      <br>
      <h2>Membrane upon membrane lies sewn tightly onto the carpet, clinging like cells. Each nucleus suspended before me questions its own purpose. A stitch is followed by an extraction.</h2>
      <br>
      <a href="https://www.katharinakemmerling.com" target="_blank"><h5 class="underline">katharinakemmerling.com</h5></a>
      <a href="https://www.instagram.com/katharina.kemmerling/" target="_blank"><h5 class="underline">Katharina Kemmerling on Instagram</h5></a>
      <a href="https://www.juergenbaumann.ch" target="_blank"><h5 class="underline">juergenbaumann.ch</h5></a>
      <a href="https://www.instagram.com/juergen_baumann/" target="_blank"><h5 class="underline">Jürgen Baumann on Instagram</h5></a>
    </div>
  </div>
</template>
<script>
  export default { 
    name: '${COMPONENT_NAME}',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {},
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {};
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {},
  };
</script>
<style lang="scss">
</style>