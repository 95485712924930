<template>
  <div class="mainArea">
    <div class="contentCenter">
      <div class="large-screen">
        <div>
  <div class="pastLinks">
    <a
      href="#"
      class="pdflink"
      @click.prevent="openPdf('https://fomoartspace.ch/docs/shaping_friction_fomo.pdf')"
    >
      <b>Shannon Zwicker, Nicolas Bischof</b> - Shaping Friction
    </a>
  </div>
  <div class="pastLinks">
    <a
      href="#"
      class="pdflink"
      @click.prevent="openPdf('https://fomoartspace.ch/docs/shape_shifters_fomo.pdf')"
    >
      <b>Orlando Marosini, Adam Thompson</b> - Shape Shifters
    </a>
  </div>
  <div class="pastLinks">
    <a
      href="#"
      class="pdflink"
      @click.prevent="openPdf('https://fomoartspace.ch/docs/aline_witschi_angelos_merges_fomo.pdf')"
    >
      <b>Aline Witschi & Angelos Merges</b> - The Radiance of Earthen Bonds
    </a>
  </div>
  <div class="pastLinks">
    <a
      href="#"
      class="pdflink"
      @click.prevent="openPdf('https://fomoartspace.ch/docs/lark_ring_fomo.pdf')"
    >
      <b>Lark Ring</b> - Hearts
    </a>
  </div>
  <div class="pastLinks">
    <a
      href="#"
      class="pdflink"
      @click.prevent="openPdf('https://fomoartspace.ch/docs/wide_angle_framework_fomo.pdf')"
    >
      <b>Irem Güngez, Oliver Kümmerli, Ramona Gschwend, Samir Seghrouchni, Sebastian Lendenmann</b> - Wide-Angle Framework - Curated By Vivianne Tat
    </a>
  </div>
  <div class="pastLinks">
    <a
      href="#"
      class="pdflink"
      @click.prevent="openPdf('https://fomoartspace.ch/docs/jorim_huber_fomo.pdf')"
    >
      <b>Jorim Huber</b> - Seeing Something As Something
    </a>
  </div>
  <h6><b>Lea & Felix Meier</b> - C-Conversations</h6>
  <div class="pastLinks">
    <a
      href="#"
      class="pdflink"
      @click.prevent="openPdf('https://fomoartspace.ch/docs/small_talk_fomo.pdf')"
    >
      <b>Maya Lama & Juris Strangots</b> - Small Talk
    </a>
  </div>
  <div class="pastLinks">
    <a
      href="#"
      class="pdflink"
      @click.prevent="openPdf('https://fomoartspace.ch/docs/martin_andereggen_fomo.pdf')"
    >
      <b>Martin Andereggen</b> - When In The Moloch, S T F U
    </a>
  </div>
  <div class="pastLinks">
    <a
      href="#"
      class="pdflink"
      @click.prevent="openPdf('https://fomoartspace.ch/docs/visarte_fomo.pdf')"
    >
      <b>FOMO Art Space Goes Visarte</b> - Flavia Trachsler, Ricardo Meli, Lark Ring, Angelos Merges, Leandra Agazzi - Curated by Leandra Agazzi
    </a>
  </div>
  <div class="pastLinks">
    <a
      href="#"
      class="pdflink"
      @click.prevent="openPdf('https://fomoartspace.ch/docs/arthur_heck_fomo.pdf')"
    >
      <b>Arthur Heck</b> - The World Is On Fire and I’m in My Bed Burning For You - Curated by Arthur Heck & Leandra Agazzi
    </a>
  </div>
  <div class="pastLinks">
    <a
      href="#"
      class="pdflink"
      @click.prevent="openPdf('https://fomoartspace.ch/fomo_limited_edition_catalogue.pdf')"
    >
      <b>FOMO Limited Edition</b> - Limited Poster Edition with 14 Artists
    </a>
  </div>
  <h6><b>Kollektiv Sennhausbach</b> - Masche N°1: Fency</h6>
  <h6><b>Tom Tom</b> - Paradise Lost</h6>
  <div class="pastLinks">
    <a
      href="#"
      class="pdflink"
      @click.prevent="openPdf('https://fomoartspace.ch/docs/friendly_space_fomo.pdf')"
    >
      <b>Friendly Space</b> - Groupshow with 6 Artists - Curated by Miran Blažek & Leandra Agazzi
    </a>
  </div>
  <div class="pastLinks">
    <a
      href="#"
      class="pdflink"
      @click.prevent="openPdf('https://fomoartspace.ch/docs/stick_n_poke_fomo.pdf')"
    >
      <b>Stick N Poke</b> - Groupshow with 20 Artists - Curated by Alana Alireza & Geraldine Belmont
    </a>
  </div>
  <h6><b>Natalia Mimran</b> - Resurrection for All - Curated by Geraldine Belmont</h6>
  <h6><b>Best of Offspace Flüelastrasse</b> - Groupshow with 32 Artists - Curated by Leandra Agazzi</h6>
  <h6><b>Linus Weber</b> - Situation 2 (Animated)</h6>
  <h6><b>Kata</b> - Wer hat den grössten</h6>
  <h6><b>Marc Kämpfen</b> - Loci</h6>
  <div class="pastLinks">
    <a
      href="#"
      class="pdflink"
      @click.prevent="openPdf('https://fomoartspace.ch/docs/nico_sebastian_meyer_offspace_fluela.pdf')"
    >
      <b>Nico Sebastian Meyer</b> - Buildings and Ruins
    </a>
  </div>
  <div class="pastLinks">
    <a
      href="#"
      class="pdflink"
      @click.prevent="openPdf('https://fomoartspace.ch/docs/leandra_agazzi_offspace_fluela.pdf')"
    >
      <b>Leandra Agazzi</b> - Critters Lurking
    </a>
  </div>
  <div class="pastLinks">
    <a
      href="#"
      class="pdflink"
      @click.prevent="openPdf('https://fomoartspace.ch/docs/carla_schleiffer_offspace_fluela.pdf')"
    >
      <b>Carla Schleiffer</b> - PS: I Know You Haven’t Forgotten
    </a>
  </div>
  <div class="pastLinks">
    <a
      href="#"
      class="pdflink"
      @click.prevent="openPdf('https://fomoartspace.ch/docs/shifting_coordinates_offspace_fluela.pdf')"
    >
      <b>Denise Maud, Denisa Svachova, Alisha Dutt Islam</b> - Shifting Coordinates
    </a>
  </div>
  <div class="pastLinks">
    <a
      href="#"
      class="pdflink"
      @click.prevent="openPdf('https://fomoartspace.ch/docs/zaccheo_zilioli_offspace_fluela.pdf')"
    >
      <b>Zaccheo Zilioli</b> - Various Systems
    </a>
  </div>
  <div class="pastLinks">
    <a
      href="#"
      class="pdflink"
      @click.prevent="openPdf('https://fomoartspace.ch/docs/flavia_trachsler_offspace_fluela.pdf')"
    >
      <b>Flavia Trachsler</b> - Oblique
    </a>
  </div>
  <div class="pastLinks">
    <a
      href="#"
      class="pdflink"
      @click.prevent="openPdf('https://fomoartspace.ch/docs/rachel_tonthat_offspace_fluela.pdf')"
    >
      <b>Rachel Tonthat</b> - Unhewn
    </a>
  </div>
  <div class="pastLinks">
    <a
      href="#"
      class="pdflink"
      @click.prevent="openPdf('https://fomoartspace.ch/docs/kesang_lamdark_offspace_fluela.pdf')"
    >
      <b>Kesang Lamdark</b> - Plastic Passion is a Sold Out Scandal
    </a>
  </div>
  <div class="pastLinks">
    <a
      href="#"
      class="pdflink"
      @click.prevent="openPdf('https://fomoartspace.ch/docs/happy_sugar_live_offspace_fluela.pdf')"
    >
      <b>Amanda del Valle, Gaia Del Santo, Anais Orr, Jasmin Irmi</b> - Happy Sugar Live - Curated by Saskia Sutter
    </a>
  </div>
  <div class="pastLinks">
    <a
      href="#"
      class="pdflink"
      @click.prevent="openPdf('https://fomoartspace.ch/docs/paint_offspace_fluela.pdf')"
    >
      <b>Andreas Niederhauser & Nicolas Bischof</b> - Paint Abs, Paint Fig, Paint
    </a>
  </div>
  <div class="pastLinks">
    <a
      href="#"
      class="pdflink"
      @click.prevent="openPdf('https://fomoartspace.ch/docs/max_gisel_offspace_fluela.pdf')"
    >
      <b>Max Gisel</b> - Building the Basement
    </a>
  </div>
  <div class="pastLinks">
    <a
      href="#"
      class="pdflink"
      @click.prevent="openPdf('https://fomoartspace.ch/docs/golfclayderman_offspace_fluela.pdf')"
    >
      <b>GolfClayderman</b> - Max Chill
    </a>
  </div>
  <div class="pastLinks">
    <a
      href="#"
      class="pdflink"
      @click.prevent="openPdf('https://fomoartspace.ch/docs/ivana_jurisic_offspace_fluela.pdf')"
    >
      <b>Ivana Jurisic</b> - A Time to Begin the End of a Time
    </a>
  </div>
</div>
    </div>
<!-- MObile ---------------------------------------------------------------------------------------------------->
<!-- MObile ---------------------------------------------------------------------------------------------------->
<!-- MObile ---------------------------------------------------------------------------------------------------->
<div class="small-screen">
<div class="pastLinks">
  <a
    href="#"
    class="pdflink"
    @click.prevent="openPdf('https://fomoartspace.ch/docs/shaping_friction_fomo.pdf')"
  >
    <b>Shannon Zwicker, Nicolas Bischof</b><br>Shaping Friction
  </a>
</div>
<div class="pastLinks">
  <a
    href="#"
    class="pdflink"
    @click.prevent="openPdf('https://fomoartspace.ch/docs/shape_shifters_fomo.pdf')"
  >
    <b>Orlando Marosini, Adam Thompson</b><br>Shape Shifters
  </a>
</div>
<div class="pastLinks">
  <a
    href="#"
    class="pdflink"
    @click.prevent="openPdf('https://fomoartspace.ch/docs/aline_witschi_angelos_merges_fomo.pdf')"
  >
    <b>Aline Witschi & Angelos Merges</b><br>The Radiance of Earthen Bonds
  </a>
</div>
<div class="pastLinks">
  <a
    href="#"
    class="pdflink"
    @click.prevent="openPdf('https://fomoartspace.ch/docs/lark_ring_fomo.pdf')"
  >
    <b>Lark Ring</b><br>Hearts
  </a>
</div>
<div class="pastLinks">
  <a
    href="#"
    class="pdflink"
    @click.prevent="openPdf('https://fomoartspace.ch/docs/wide_angle_framework_fomo.pdf')"
  >
    <b>Irem Güngez, Oliver Kümmerli, Ramona Gschwend, Samir Seghrouchni, Sebastian Lendenmann</b><br>Wide-Angle Framework<br>Curated By Vivianne Tat
  </a>
</div>
<div class="pastLinks">
  <a
    href="#"
    class="pdflink"
    @click.prevent="openPdf('https://fomoartspace.ch/docs/jorim_huber_fomo.pdf')"
  >
    <b>Jorim Huber</b><br>Seeing Something As Something
  </a>
</div>
<div class="pastLinks">
  <h6><b>Lea & Felix Meier</b><br>C-Conversations</h6>
</div>
<div class="pastLinks">
  <a
    href="#"
    class="pdflink"
    @click.prevent="openPdf('https://fomoartspace.ch/docs/small_talk_fomo.pdf')"
  >
    <b>Maya Lama & Juris Strangots</b><br>Small Talk
  </a>
</div>
<div class="pastLinks">
  <a
    href="#"
    class="pdflink"
    @click.prevent="openPdf('https://fomoartspace.ch/docs/martin_andereggen_fomo.pdf')"
  >
    <b>Martin Andereggen</b><br>When In The Moloch, S T F U
  </a>
</div>
<div class="pastLinks">
  <a
    href="#"
    class="pdflink"
    @click.prevent="openPdf('https://fomoartspace.ch/docs/visarte_fomo.pdf')"
  >
    <b>FOMO Art Space Goes Visarte</b><br>Flavia Trachsler, Ricardo Meli, Lark Ring, Angelos Merges, Leandra Agazzi<br>Curated by Leandra Agazzi
  </a>
</div>
<div class="pastLinks">
  <a
    href="#"
    class="pdflink"
    @click.prevent="openPdf('https://fomoartspace.ch/docs/arthur_heck_fomo.pdf')"
  >
    <b>Arthur Heck</b><br>The World Is On Fire and I’m in My Bed Burning For You<br>Curated by Arthur Heck & Leandra Agazzi
  </a>
</div>
<div class="pastLinks">
  <a
    href="#"
    class="pdflink"
    @click.prevent="openPdf('https://fomoartspace.ch/fomo_limited_edition_catalogue.pdf')"
  >
    <b>FOMO Limited Edition</b><br>Limited Poster Edition with 14 Artists
  </a>
</div>
<div class="pastLinks">
  <h6><b>Kollektiv Sennhausbach</b><br>Masche N°1: Fency</h6>
</div>
<div class="pastLinks">
  <h6><b>Tom Tom</b><br>Paradise Lost</h6>
</div>
<div class="pastLinks">
  <a
    href="#"
    class="pdflink"
    @click.prevent="openPdf('https://fomoartspace.ch/docs/friendly_space_fomo.pdf')"
  >
    <b>Friendly Space</b><br>Groupshow with 6 Artists<br>Curated by Miran Blažek & Leandra Agazzi
  </a>
</div>
<div class="pastLinks">
  <a
    href="#"
    class="pdflink"
    @click.prevent="openPdf('https://fomoartspace.ch/docs/stick_n_poke_fomo.pdf')"
  >
    <b>Stick N Poke</b><br>Groupshow with 20 Artists<br>Curated by Alana Alireza & Geraldine Belmont
  </a>
</div>
<div class="pastLinks">
  <h6><b>Natalia Mimran</b><br>Resurrection for All<br>Curated by Geraldine Belmont</h6>
</div>
<div class="pastLinks">
  <h6><b>Best of Offspace Flüelastrasse</b><br>Groupshow with 32 Artists<br>Curated by Leandra Agazzi</h6>
</div>
<div class="pastLinks">
  <h6><b>Linus Weber</b><br>Situation 2 (Animated)</h6>
</div>
<div class="pastLinks">
  <h6><b>Kata</b><br>Wer hat den grössten</h6>
</div>
<div class="pastLinks">
  <h6><b>Marc Kämpfen</b><br>Loci</h6>
</div>
<div class="pastLinks">
  <a
    href="#"
    class="pdflink"
    @click.prevent="openPdf('https://fomoartspace.ch/docs/nico_sebastian_meyer_offspace_fluela.pdf')"
  >
    <b>Nico Sebastian Meyer</b><br>Buildings and Ruins
  </a>
</div>
<div class="pastLinks">
  <a
    href="#"
    class="pdflink"
    @click.prevent="openPdf('https://fomoartspace.ch/docs/leandra_agazzi_offspace_fluela.pdf')"
  >
    <b>Leandra Agazzi</b><br>Critters Lurking
  </a>
</div>
<div class="pastLinks">
  <a
    href="#"
    class="pdflink"
    @click.prevent="openPdf('https://fomoartspace.ch/docs/carla_schleiffer_offspace_fluela.pdf')"
  >
    <b>Carla Schleiffer</b><br>PS: I Know You Haven’t Forgotten
  </a>
</div>
<div class="pastLinks">
  <a
    href="#"
    class="pdflink"
    @click.prevent="openPdf('https://fomoartspace.ch/docs/shifting_coordinates_offspace_fluela.pdf')"
  >
    <b>Denise Maud, Denisa Svachova, Alisha Dutt Islam</b><br>Shifting Coordinates
  </a>
</div>
<div class="pastLinks">
  <a
    href="#"
    class="pdflink"
    @click.prevent="openPdf('https://fomoartspace.ch/docs/zaccheo_zilioli_offspace_fluela.pdf')"
  >
    <b>Zaccheo Zilioli</b><br>Various Systems
  </a>
</div>
<div class="pastLinks">
  <a
    href="#"
    class="pdflink"
    @click.prevent="openPdf('https://fomoartspace.ch/docs/flavia_trachsler_offspace_fluela.pdf')"
  >
    <b>Flavia Trachsler</b><br>Oblique
  </a>
</div>
<div class="pastLinks">
  <a
    href="#"
    class="pdflink"
    @click.prevent="openPdf('https://fomoartspace.ch/docs/rachel_tonthat_offspace_fluela.pdf')"
  >
    <b>Rachel Tonthat</b><br>Unhewn
  </a>
</div>
<div class="pastLinks">
  <a
    href="#"
    class="pdflink"
    @click.prevent="openPdf('https://fomoartspace.ch/docs/kesang_lamdark_offspace_fluela.pdf')"
  >
    <b>Kesang Lamdark</b><br>Plastic Passion is a Sold Out Scandal
  </a>
</div>
<div class="pastLinks">
  <a
    href="#"
    class="pdflink"
    @click.prevent="openPdf('https://fomoartspace.ch/docs/happy_sugar_live_offspace_fluela.pdf')"
  >
    <b>Amanda del Valle, Gaia Del Santo, Anais Orr, Jasmin Irmi</b><br>Happy Sugar Live<br>Curated by Saskia Sutter
  </a>
</div>
<div class="pastLinks">
  <a
    href="#"
    class="pdflink"
    @click.prevent="openPdf('https://fomoartspace.ch/docs/paint_offspace_fluela.pdf')"
  >
    <b>Andreas Niederhauser & Nicolas Bischof</b><br>Paint Abs, Paint Fig, Paint
  </a>
</div>
<div class="pastLinks">
  <a
    href="#"
    class="pdflink"
    @click.prevent="openPdf('https://fomoartspace.ch/docs/max_gisel_offspace_fluela.pdf')"
  >
    <b>Max Gisel</b><br>Building the Basement
  </a>
</div>
<div class="pastLinks">
  <a
    href="#"
    class="pdflink"
    @click.prevent="openPdf('https://fomoartspace.ch/docs/golfclayderman_offspace_fluela.pdf')"
  >
    <b>GolfClayderman</b><br>Max Chill
  </a>
</div>
<div class="pastLinks">
  <a
    href="#"
    class="pdflink"
    @click.prevent="openPdf('https://fomoartspace.ch/docs/ivana_jurisic_offspace_fluela.pdf')"
  >
    <b>Ivana Jurisic</b><br>A Time to Begin the End of a Time
  </a>
</div>
</div>

    </div>
  </div>
</template>
<script>
  export default {
    name: '${COMPONENT_NAME}',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {},
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {};
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    mounted() {
      // Warte, bis der DOM vollständig geladen ist
    },
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {
      openPdf(pdfUrl) {
        // Überprüfen, ob das Gerät ein Android-Gerät ist
        const isAndroid = /Android|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

        // Überprüfen, ob der Browser PDFs nativ unterstützt
        const isPdfSupported = (() => {
          const canvas = document.createElement('canvas');
          return !!(canvas.getContext && canvas.getContext('2d'));
        })();

        if (isAndroid || !isPdfSupported) {
          // Google Docs Viewer verwenden
          const viewerUrl = `https://docs.google.com/viewerng/viewer?url=${encodeURIComponent(pdfUrl)}`;
          window.open(viewerUrl, '_blank', 'noopener,noreferrer');
        } else {
          // Direkt im Browser öffnen
          window.open(pdfUrl, '_blank', 'noopener,noreferrer');
        }
      },
    },
  };
</script>


<style lang="scss">
</style>